import React from 'react';
import picture from './picture.jpeg';
import picture1 from './picture1.jpeg';
import picture2 from './picture2.jpeg';
import "./Images.css"

const homeText = {
    "RU" : {
        1: "Производство обуви – сложный процесс, включающий несколько этапов. Первый этап — это этап проектирования, на котором дизайнеры набрасывают общий вид и ощущение обуви. Это включает в себя выбор стиля, цвета и материалов, которые будут использоваться. Затем проект преобразуется в технический чертеж, который служит основой производственного процесса. Фаза проектирования имеет решающее значение, поскольку она задает тон остальному производственному процессу и в конечном итоге определяет внешний вид и функциональность конечного продукта.",
        2: "Следующий этап – производство деталей обуви. Это включает в себя раскройку и придание формы различным компонентам обуви, таким как верхняя часть, стелька, межподошва и подошва. Материалы, используемые на этом этапе, могут быть самыми разными: от кожи и синтетических материалов для верха до резины или пластика для подошв. Вырезанные части затем сшиваются или склеиваются вместе, образуя базовую структуру обуви. Этот процесс требует точности и навыков, чтобы гарантировать, что детали правильно соединяются друг с другом и обувь удобна в ношении.",
        3: "Заключительный этап изготовления обуви – сборка и отделка. На этом этапе различные части обуви собираются вместе. Это может включать сшивание, склеивание или даже термосварку деталей. После того, как обувь собрана, она проходит процесс отделки, который может включать полировку, покраску или другие обработки, улучшающие внешний вид обуви. Затем обувь проверяется на качество и упаковывается для распространения. Этот заключительный этап имеет решающее значение, поскольку он гарантирует, что готовый продукт соответствует проектным спецификациям и готов к продаже. Весь процесс производства обуви представляет собой смесь искусства и науки, требующую как творчества, так и технических знаний."
    },
    "EN" : {
        1: "Shoe manufacturing is a complex process that involves multiple stages. The first stage is the design phase, where designers sketch out the overall look and feel of the shoe. This includes deciding on the style, color, and materials to be used. The design is then converted into a technical drawing, which serves as a blueprint for the manufacturing process. The design phase is crucial as it sets the tone for the rest of the production process and ultimately determines the final product’s appearance and functionality.",
        2: "The next stage is the production of the shoe parts. This involves cutting and shaping the various components of the shoe, such as the upper part, the insole, the midsole, and the outsole. The materials used in this stage can vary widely, from leather and synthetic materials for the upper part to rubber or plastic for the soles. The cut pieces are then stitched or glued together to form the basic structure of the shoe. This process requires precision and skill to ensure that the parts fit together correctly and the shoe is comfortable to wear.",
        3: "The final stage of shoe manufacturing is the assembly and finishing. In this stage, the various parts of the shoe are assembled together. This can involve sewing, gluing, or even heat-fusing the parts together. Once the shoe has been assembled, it goes through a finishing process, which can include polishing, painting, or other treatments to enhance the shoe’s appearance. The shoes are then inspected for quality and packed for distribution. This final stage is crucial as it ensures that the finished product meets the design specifications and is ready for sale. The entire process of shoe manufacturing is a blend of art and science, requiring both creativity and technical knowledge."
    }
}

function Images({language, setLanguage}) {
  return (
    <div>
      <div className="row">
        <img src={picture} className="picture" alt='main-page'/>
        <p className='home-text'>{homeText[language]["1"]}</p>
      </div>
      <div className="row reverse">
        <img src={picture1} className="picture" alt='main-page'/>
        <p className='home-text'>{homeText[language]["2"]}</p>
      </div>
      <div className="row">
        <img src={picture2} className="picture" alt='main-page'/>
        <p className='home-text'>{homeText[language]["3"]}</p>
      </div>
    </div>
  );
}

export default Images;
