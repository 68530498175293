import Footer from "./Footer"
import Header from "./Header"
import picture3 from './picture3.jpeg';
import picture5 from './picture5.jpeg';
import picture6 from './picture6.jpeg';
import picture7 from './picture7.jpeg';
import picture8 from './picture8.jpeg';
import picture9 from './picture9.jpeg';
import "./Pictures.css"

export default function Pictures({language, setLanguage}) {
    return (
        <div>
            <Header language={language} setLanguage={setLanguage}/>
            <div className="container">
                <div className="crop">
                <img src={picture3} alt='main-page' loading="lazy"/>
                </div>
                <div className="crop">
                <img src={picture5} alt='main-page' loading="lazy"/>
                </div>
                <div className="crop">
                <img src={picture6} alt='main-page' loading="lazy"/>
                </div>
                <div className="crop">
                <img src={picture7} alt='main-page' loading="lazy"/>
                </div>
                <div className="crop">
                <img src={picture8} alt='main-page' loading="lazy"/>
                </div>
                <div className="crop">
                <img src={picture9} alt='main-page' loading="lazy"/>
                </div>
            </div>
            <Footer language={language} setLanguage={setLanguage}/>
        </div>
    )
}