import Menu from './Menu'
import "./Header.css"
import { Link } from 'react-router-dom';

export default function About({language, setLanguage}) {
    return (
        <div className="App">
            <div className="company-name">
                <Link className="company-name" to="/" >{language === "EN" ? "Technoexport" : "Техноэкспорт"}</Link>
            </div>
             <header className="App-header">
                <Menu language={language} setLanguage={setLanguage}/>
            </header>
        </div>
    )
}