import React from 'react';
import "./Footer.css"

const homeText = {
    "RU" : {
        1: '© 2023 ООО ТЕХНОЭКСПОРТ',
        2: [<br />, 'Контакты', <br />, 'Email: techno.echport@gmail.com', <br />, 'Тел: +373 (552) 7-88-46', <br />, 'Адрес: улица Дзержинского 2/2, MD-3200, Бендеры, Молдова']},
    "EN" : {
        1: '© 2023 LTD TECHNOEXPORT',
        2: [<br />, 'Contacts', <br />, 'Email: techno.echport@gmail.com', <br />, 'Phone: +373 (552) 7-88-46', <br />, 'Address: Dzerzhinskogo 2/2, MD-3200, Bender, Moldova']
    }
}

function Footer({language, setLanguage}) {
  return (
    <footer className="footer">
      <div className="footer-container">
        <p className='footer-text'>{homeText[language]["1"]}</p>
        <p className='footer-text'>{homeText[language]["2"]}</p>
      </div>
    </footer>
  );
}

export default Footer;
