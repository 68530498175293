import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './About'
import Home from './Home'
import Pictures from './Pictures';
import Contacts from './Contacts';


function App() {
  const [language, setLanguage] = useState('EN');
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home language={language} setLanguage={setLanguage}/>} />
          <Route path="/about" element={<About language={language} setLanguage={setLanguage} />}/>
          <Route path="/pictures" element={<Pictures language={language} setLanguage={setLanguage}/>} />
          <Route path="/contacts" element={<Contacts language={language} setLanguage={setLanguage}/>} />
        </Routes>
      </Router>
  );
}

export default App;
