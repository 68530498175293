import Footer from "./Footer"
import Header from "./Header"
import './About.css'

const aboutText = {
    "RU" : {
        1: 'Компания Техноэкспорт начала свою производственную деятельность в 2002 году в составе производственной группы компаний ЗАО «Данастр» «Комфошуз», «Софтшуз» занимающихся производством обуви.',
        2: 'За этот период производство и экспорт обуви вырос до 1,5 млн пар обуви в год. География экспорта охватила практически все страны Европы, Азия, страны СНГ, США. Нашими клиентами стали такие компании Gemini Italia, The Flexx USA, CCC, Deichman, Tomas Muenz, Benvenutti, Vale Verde.',
        3: 'Кроме производства обуви за эти годы компания освоила собственное производство комплектующих для производства обуви. Производство подошвы из ТЭП, ТПУ, каблуки и набойки, стельки, и подошва из ECO материала заняли значительную часть объемов производства компании.',
        4: 'В 2019 году компания стала функционировать как отдельная производственная структура и это позволило охватить другие сферы деятельности. Техническое консультирование, проверка и оценка производств обуви в других регионах, юридическая и коммерческая консультация по ведению бизнеса в интересующих заказчика регионах.'
        },
    "EN" : {
        1: 'The Technoexport company began its production activities in 2002 as part of the production group of companies CJSC Danastr, Comfoshoes, Softshoes, engaged in the production of footwear.',
        2: 'During this period, shoe production and exports increased to 1.5 million pairs of shoes per year. The geography of exports covered almost all countries of Europe, Asia, CIS countries, and the USA. Our clients include the following companies: Gemini Italia, The Flexx USA, CCC, Deichman, Tomas Muenz, Benvenutti, Vale Verde.',
        3: `In addition to the production of shoes, over the years the company has mastered its own production of components for the production of shoes. The production of soles from TEP, TPU, heels and caps, insoles, and soles from ECO material occupied a significant part of the company's production volumes.`,
        4: 'In 2019, the company began to operate as a separate production structure and this made it possible to cover other areas of activity. Technical consulting, inspection and assessment of footwear production in other regions, legal and commercial advice on doing business in regions of interest to the customer.'
        },
}

export default function About({language, setLanguage}) {
    return (
        <div>
        <Header language={language} setLanguage={setLanguage}/>
        <div className="about-section">
            <p>{aboutText[language]['1']}</p>
            <p>{aboutText[language]['2']}</p>
            <p>{aboutText[language]['3']}</p>
            <p>{aboutText[language]['4']}</p>
        </div>
        <Footer language={language} setLanguage={setLanguage}/>
        </div>
    )
}