import Footer from "./Footer"
import Header from "./Header"
import factory from "./factory.jpeg"
import "./Contacts.css"

const homeText = {
    "RU" : {
        1: [<br />, 'Контакты', <br />, <br />, 'Email: techno.echport@gmail.com', <br />, 'Тел: +373 (552) 7-88-46', <br />, 'Адрес: улица Дзержинского 2/2', <br />, 'MD-3200, Бендеры, Молдова', <br />, <br />, 'Директор:', <br />, 'Чебан Юрий Акимович', <br />, <br />, 'Отдел производства:', <br />, 'тел: +373 (552) 2-71-69'],
    },
    "EN" : {
        1: [<br />, 'Contacts', <br />, <br />, 'Email: techno.echport@gmail.com', <br />, 'Phone: +373 (552) 7-88-46', <br />, 'Address: Dzerzhinskogo 2/2', <br />, 'MD-3200, Bender, Moldova', <br />, <br />, 'Director:', <br />, 'Iurii Ceban', <br />, <br />, 'Production department:', <br />, 'tel: +373 (552) 2-71-69'],
    }
}

export default function Contacts({language, setLanguage}) {
    return (
        <div>
            <Header language={language} setLanguage={setLanguage}/>
            <div className="row">
                <img src={factory}  className="picture" alt='main-page' loading="lazy"/>
                <p className='home-text'>{homeText[language]["1"]}</p>
                <p className='home-text'>{homeText[language]["2"]}</p>
            </div>
            <Footer language={language} setLanguage={setLanguage}/>
        </div>
    )
}