import React from 'react';
import "./Menu.css"
import { Link } from 'react-router-dom';

const menuItems = {
    "RU" : {
        1: "О нас",
        2: "Галерея",
        3: "Контакты"
    },
    "EN" : {
        1: "About",
        2: "Pictures",
        3: "Contacts"
    }
}

function Menu ({language, setLanguage}) {
    
    return (
      <div>
        <nav>
          <ul>
            <li className="menu-item"><Link to="/about">{menuItems[language]["1"]}</Link></li>
            <li className="menu-item"><Link to="/pictures">{menuItems[language]["2"]}</Link></li>
            <li className="menu-item"><Link to="/contacts">{menuItems[language]["3"]}</Link></li>
            <button onClick={() => language === 'EN' ? setLanguage('RU') : setLanguage('EN')}>{language}</button>
          </ul>
        </nav>
      </div>
    );
  }

export default Menu;
