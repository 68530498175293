import Footer from "./Footer";
import Header from "./Header"
import Images from "./Images";

function Home({language, setLanguage}) {
    return (
        <div>
            <Header language={language} setLanguage={setLanguage}/>
            <Images language={language} setLanguage={setLanguage}/>
            <Footer language={language} setLanguage={setLanguage}/>
        </div>
    )
}
export default Home;